import Button from '@/components/Button';
import type { CalendarEvent } from '@/components/Calendar';
import Calendar from '@/components/Calendar';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import LoadingOverlay from '@/components/LoadingOverlay';
import MuiTabs from '@/components/MuiTabs';
import PatientAdd from '@/components/PatientAdd';
import PatientLink from '@/components/PatientLink';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import StarredPatient from '@/components/StarredPatient';
import TruentityChip from '@/components/TruentityChip';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import TruentityTextField from '@/components/TruentityTextField';
import AdministrationContext from '@/context/administrationContext';
import SideMenuContext from '@/context/sideMenuContext';
import Link from '@/elements/Link';
import type {
  GetAccountsByFilterResponse,
  GetAccountsResponse,
  GetAccountsSuperAdminResponse,
  GetMyPatientsResponse,
  GetPatientDetailResponse,
  GetRelyingPartyAdminsResponse
} from '@/graphql/account';
import {
  FollowUpCategoryTypes,
  FollowUpStatusTypes,
  FollowUpTypes,
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUPER_ADMIN,
  GET_ACCOUNT_BY_FILTER_SIMPLIFIED,
  GET_GLOBAL_SEARCH_ACCOUNTS_COUNTS,
  GET_MY_PATIENTS_SIMPLIFIED,
  GET_PATIENT_DETAIL,
  GET_PATIENT_DETAIL_FOR_CALENDAR,
  GET_RELYING_PARTY_ADMINS
} from '@/graphql/account';
import type { GetClientOrganizationsResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { MedicalServices, Role } from '@/types/admin';
import type { ClientOrganizationObject, ClientStoreObject, RelyingPartyAdminsObject } from '@/types/administration';
import type { MedicalServiceType } from '@/types/graphql';
import type Meta from '@/types/meta';
import { PatientsListSearchFilters } from '@/types/patients-list-filters';
import type { AccountsMonitoringDeviceType, ContactedType, RpmFollowupConsultType } from '@/types/remotePatientMonitoring';
import { getAccountRedirectUrl, getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getFirstDateOfCurrentMonth } from '@/util/date';
import type { HandlerMap } from '@/util/error';
import { handleGraphQLError } from '@/util/error';
import { formatDate, formatDateIgnoreTZ, formatDateIgnoreTZRemoveDays, formatTime } from '@/util/format';
import { addIfExists } from '@/util/object';
import { phoneNumberFormat, removeNonNumeric } from '@/util/string';
import type { ApolloError } from '@apollo/client';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import type { EventSourceInput } from '@fullcalendar/core';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SearchIcon from '@mui/icons-material/Search';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import type { SelectChangeEvent } from '@mui/material';
import { Badge, Collapse, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import type { GridAlignment, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { InsightsType } from './insights-types';
import PatientInfoDrawer from './PatientInfoDrawer';
import { PatientsDataFilter } from './PatientsDataFilter';

const SELECT_DEFAULT: { id: string; name: string }[] = [
  { id: 'unassigned', name: 'Unassigned' },
  { id: 'all', name: 'All' }
];

const SELECT_DEFAULT_ADMIN: { id: string; name: string }[] = [{ id: 'all', name: 'All' }];

type LookupFilterOptions = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string | null;
  truentityId?: string;
  organization?: string;
  relyingPartyAdmin?: string;
  orgStore?: string;
  nextCallSuggestedAt?: Date;
  phoneNumber?: string | null;
};

export type PatientsUser = {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
};

export type AccountMetric = {
  rpmPriorityScore: number;
  medicationData: string;
  claimsData: string;
};

export type PatientsData = {
  id: string;
  truentityId: string;
  user: PatientsUser;
  numOfReadingsInCurrentMonth: number;
  ehrId: string;
  totalTimeSpentSecsInCurrentMonth: number;
  rpmStatus: string;
  mtmStatus: string;
  tcmStatus: string;
  rpmStatusComment?: string;
  rpmApprovalStatus: string;
  rpmStatusUpdatedAt?: Date;
  rpmEnrolledAt?: string;
  rpmUnenrolledAt?: string;
  onboardedAt: string;
  clientOrgs: { id: string; name: string }[];
  clientStores: { id: string; name: string }[];
  currentMedsCount?: number;
  lastMedRecDate?: Date;
  numQuickUploads: number;
  numQuickUploadsNew: number;
  address: string;
  phone: string;
  birthDate: string;
  healthPlan: {
    id: string;
    orgName: string;
  };
  gender: string;
  emergencyContactName: string;
  emergencyContactPhone: string;
  emergencyContactEmail: string;
  addressLine1: string;
  addressLine2: string;
  addressState: string;
  addressCity: string;
  zipcode: string;
  consent: string;
  doNotCall: boolean;
  doNotCallLastSpecifiedAt: Date | null;
  accountsFollowupReminders: FollowUpRemindersTypes[];
  accountsFollowupRemindersByAdmin: FollowUpRemindersTypes[];
  primaryLanguage: string;
  lastImport?: {
    status: string;
    lastImportDate: string;
  };
  data?: InsightsType;
  jsonData?: string;
  numPendingTasks: number;
  numCompletedTasks: number;
  lastEncounterDate: string;
  setting: string;
  isTempVitalAccessBlocked: boolean;
  isTempVitalAccessConfigured: boolean;
  accountsAssignments: {
    relyingPartyAdmin: {
      id: string;
      name: string;
    };
  }[];
  currentAccountsAssignments: {
    relyingPartyAdmin: {
      id: string;
      name: string;
    };
  }[];
  accountsMonitoringDevices: AccountsMonitoringDeviceType[];
  dateEnrolled?: string;
  devices?: {
    id: string;
    name: string;
    hardwareId: string;
    readings: { id: string; key: string; value: string; unit: string }[];
  }[];
  dateOfLastReview?: string;
  medicalServices: MedicalServiceType[];
  isRpmReportAvailable?: boolean;
  lastContacted: { type: ContactedType; date: string };
  accountRpmMonitoredCondition: string[];
  accountRpmDiagnosis?: string[];
  rpmSignOffStatus?: string;
  accountSummary?: {
    isTelehealthVisitLaunched: boolean;
  };
  lastCompletedFollowup?: Partial<RpmFollowupConsultType>;
  numCriticalAlerts?: number;
  accountMetric?: AccountMetric;
};

const Patients = () => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { setContent } = useContext(SideMenuContext);
  const { setRelyingPartyAdmins } = useContext(AdministrationContext);
  const [relyingPartiesAdminAll, setRelyingPartiesAdminAll] = useState<RelyingPartyAdminsObject[]>([]);
  const [collapseFilter, setCollapseFilter] = useState(true);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const [totalResultCount, setTotalResultCount] = useState(0);
  const { roleType, handleLogout } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const [patients, setPatients] = useState<PatientsData[]>([]);
  const [filteredPatients, setFilteredPatients] = useState<PatientsData[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<PatientsData | null>(null);
  const [searchParams] = useSearchParams();
  const [patientType, setPatientType] = useState<PatientsListSearchFilters>(PatientsListSearchFilters.Lookup);
  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent>([]);
  const [isFollowUpUpdated, setIsFollowUpUpdated] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [clickedFollowupId, setClickedFollowupId] = useState<string | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<{ value: string; filterOption: string }>({
    value: '',
    filterOption: ''
  });
  const [globalAccountCount, setGlobalAccountCount] = useState<number>(0);

  const submittedPatientLookupData = useRef<string>('');
  const submittedGlobalAccountCount = useRef<number>(0);

  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const isTCMViewable = useShowMedicalServices(MedicalServices.TCM);

  const [rowCount, setRowCount] = useState(0);

  const isPSM = useMemo(() => roleType === Role.ADMIN, [roleType]);

  const {
    loading: relyingPartyAdminDataLoading,
    data: relyingPartyAdminData,
    called: relyingPartyAdminDataCalled
  } = useQuery<GetRelyingPartyAdminsResponse>(GET_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const [getInfo, { loading, error: accountError, refetch: refetchGetInfo }] = useLazyQuery<GetAccountsResponse>(GET_ACCOUNTS, {
    notifyOnNetworkStatusChange: true
  });
  const [getInfoSuperAdmin, { loading: loadingSuperAdmin, error: adminAccountError, refetch: refetchGetInfoSuperAdmins }] =
    useLazyQuery<GetAccountsSuperAdminResponse>(GET_ACCOUNTS_SUPER_ADMIN, {
      notifyOnNetworkStatusChange: true
    });
  const [getInfoMyPatients, { loading: loadingMyPatients, error: myPatientsError, refetch: refetchGetInfoMyPatients }] =
    useLazyQuery<GetMyPatientsResponse>(GET_MY_PATIENTS_SIMPLIFIED, {
      notifyOnNetworkStatusChange: true
    });

  const [getInfoByLookupSimplified, { data: lookupData, loading: loadingPatients, error: errorLookup, refetch: refetchLookups }] =
    useLazyQuery<GetAccountsByFilterResponse>(GET_ACCOUNT_BY_FILTER_SIMPLIFIED, {
      fetchPolicy: 'cache-and-network'
    });

  const [getCalendarEventDetails] = useLazyQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL_FOR_CALENDAR);
  const [getPatientDetail] = useLazyQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL);
  const [getGlobalAccountCount, { data: globalAccountCountData }] = useLazyQuery<{ accountsSearch: { meta: Meta } }>(
    GET_GLOBAL_SEARCH_ACCOUNTS_COUNTS,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const [formDefault, setFormDefault] = useState<LookupFilterOptions>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    truentityId: '',
    dateOfBirth: null,
    nextCallSuggestedAt: getFirstDateOfCurrentMonth(new Date()),
    organization: 'all',
    relyingPartyAdmin: isSuperAdmin || isPSM || patientType === PatientsListSearchFilters.Lookup ? 'all' : currentUser?.id,
    orgStore: undefined
  });

  const {
    control,
    reset: resetLookupForm,
    watch,
    setValue,
    getValues,
    handleSubmit
  } = useForm<LookupFilterOptions>({ defaultValues: formDefault });
  const selectedOrg = watch('organization');

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const phoneNumber = watch('phoneNumber');
  const dateOfBirth = watch('dateOfBirth');

  const formRef = useRef<HTMLFormElement>();

  const onSubmit: SubmitHandler<LookupFilterOptions> = data =>
    patientType === PatientsListSearchFilters.ToCallNext ? callGetInfoByLookup(data) : handleLookup(data);

  //TODO:  I think this needs to be handled in the graphql client script
  const errorHandlerMap = useMemo(() => {
    return {
      AUTHORIZATION_FAILED: err => {
        console.error(err);
        handleLogout();
        navigate('/login');
        enqueueSnackbar('Request not allowed, logging out', {
          variant: 'error'
        });
      },
      all: err => {
        console.error(err);
        enqueueSnackbar('An error has occured', { variant: 'error' });
      }
    } as HandlerMap;
  }, [enqueueSnackbar, handleLogout, navigate]);

  const resetFilter = useCallback(() => {
    setFilteredPatients(patients);
  }, [patients]);

  const updateData = (data: number) => {
    setCurrentPage(data);
  };

  const columns: GridColDef<PatientsData>[] = useMemo(
    () => [
      {
        field: 'truentityId',
        headerName: 'Starred',
        sortable: false,
        width: 100,
        align: 'center' as GridAlignment,
        headerAlign: 'center' as GridAlignment,
        renderCell: params => {
          return <StarredPatient truentityId={params.value} />;
        }
      },
      {
        field: 'name',
        headerName: 'Patient Name',
        minWidth: 250,
        sortable: true,
        valueGetter: params => getAccountUserFullName(params.row.user),
        renderCell: params => {
          return <PatientLink {...params.row} />;
        }
      },
      {
        field: 'dateOfBirth',
        headerName: 'DOB',
        sortable: true,
        flex: 1,
        valueGetter: params => (params.row.birthDate ? formatDate(new Date(params.row.birthDate.replace(/-/g, '/'))) : ''),
        align: 'center' as GridAlignment,
        headerAlign: 'center' as GridAlignment
      },
      {
        field: 'phone',
        headerName: 'Phone Number',
        sortable: true,
        flex: 1,
        align: 'center' as GridAlignment,
        headerAlign: 'center' as GridAlignment,
        renderCell: params => {
          return <TruentityPhoneNumber value={params.value} />;
        }
      },
      {
        field: 'programs',
        headerName: 'Programs',
        sortable: false,
        flex: 1,
        align: 'center' as GridAlignment,
        headerAlign: 'center' as GridAlignment,
        filterable: false,
        renderCell: params => {
          const { rpmStatus, mtmStatus, tcmStatus, truentityId, accountsMonitoringDevices } = params.row;

          const generateRedirectUrl = (service, status) => {
            if (status && status.length > 0) {
              return getAccountRedirectUrl(truentityId, service, status, accountsMonitoringDevices);
            }
            return '';
          };

          const isViewable = service => {
            switch (service) {
              case MedicalServices.RPM:
                return isRPMViewable;
              case MedicalServices.MTM:
                return isMTMViewable;
              case MedicalServices.TCM:
                return isTCMViewable;
              default:
                return false;
            }
          };

          return (
            <Stack spacing={1} direction="row" justifyContent={'center'}>
              {rpmStatus && rpmStatus.length > 0 ? (
                isViewable(MedicalServices.RPM) ? (
                  <Link to={generateRedirectUrl(MedicalServices.RPM, rpmStatus)}>
                    <TruentityChip variant="outlined" label={'RPM'} textColor={'primary.main'} borderColor={'primary.main'} />
                  </Link>
                ) : (
                  <TruentityChip
                    variant="outlined"
                    label={'RPM'}
                    textColor={'primary.main'}
                    borderColor={'success.main'}
                    tooltipTitle="This service is being provided to this patient by another organization. Please contact support for more details."
                  />
                )
              ) : (
                <TruentityChip
                  isDisabled={true}
                  variant="outlined"
                  label={'RPM'}
                  textColor={'primary.main'}
                  borderColor={'primary.main'}
                  tooltipTitle="Please contact support to have your organization provide this service to this patient."
                />
              )}

              {mtmStatus && mtmStatus.length > 0 ? (
                isViewable(MedicalServices.MTM) ? (
                  <Link to={generateRedirectUrl(MedicalServices.MTM, mtmStatus)}>
                    <TruentityChip variant="outlined" label={'MTM'} textColor={'secondary.main'} borderColor={'secondary.main'} />
                  </Link>
                ) : (
                  <TruentityChip
                    variant="outlined"
                    label={'MTM'}
                    textColor={'secondary.main'}
                    borderColor={'success.main'}
                    tooltipTitle="This service is being provided to this patient by another organization. Please contact support for more details."
                  />
                )
              ) : (
                <TruentityChip
                  isDisabled={true}
                  variant="outlined"
                  label={'MTM'}
                  textColor={'secondary.main'}
                  borderColor={'secondary.main'}
                  tooltipTitle="Please contact support to have your organization provide this service to this patient."
                />
              )}

              {tcmStatus && tcmStatus.length > 0 ? (
                isViewable(MedicalServices.TCM) ? (
                  <Link to={generateRedirectUrl(MedicalServices.TCM, tcmStatus)}>
                    <TruentityChip variant="outlined" label={'TCM'} textColor={'info.main'} borderColor={'info.main'} />
                  </Link>
                ) : (
                  <TruentityChip
                    variant="outlined"
                    label={'TCM'}
                    textColor={'info.main'}
                    borderColor={'success.main'}
                    tooltipTitle="This service is being provided to this patient by another organization. Please contact support for more details."
                  />
                )
              ) : (
                <TruentityChip
                  isDisabled={true}
                  variant="outlined"
                  label={'TCM'}
                  textColor={'info.main'}
                  borderColor={'info.main'}
                  tooltipTitle="Please contact support to have your organization provide this service to this patient."
                />
              )}
            </Stack>
          );
        }
      }
    ],
    [isProviderAdmin]
  );

  const unableToReloadPatients = err => {
    enqueueSnackbar('Unable to reload patients', { variant: 'error' });
    console.error(err);
  };

  const refetchPatients = () => {
    switch (patientType) {
      default:
        refetchGetInfoMyPatients().catch(unableToReloadPatients);
        break;

      case PatientsListSearchFilters.Lookup:
      case PatientsListSearchFilters.ToCallNext:
        break;

      case PatientsListSearchFilters.Recent:
      case PatientsListSearchFilters.All: {
        if (isProviderAdmin) {
          refetchGetInfo().catch(unableToReloadPatients);
        } else {
          refetchGetInfoSuperAdmins().catch(unableToReloadPatients);
        }

        break;
      }
    }
  };

  const handleLookup = async (values: LookupFilterOptions) => {
    if (
      [values?.firstName, values?.lastName, values?.dateOfBirth, values?.phoneNumber, values?.truentityId].every(
        entry => !entry || entry?.length === 0
      )
    ) {
      enqueueSnackbar('One of the First Name, Last Name, Phone Number and Date of Birth is required', {
        variant: 'error'
      });

      resetLookupForm();
      return;
    }

    await callGetInfoByLookup(values);
  };

  const colorMapping = {
    [FollowUpCategoryTypes.ALERT_REVIEW]: {
      [FollowUpStatusTypes.PENDING]: color.purple500,
      [FollowUpStatusTypes.COMPLETED]: color.purple700
    },
    [FollowUpCategoryTypes.CHART_REVIEW]: {
      [FollowUpStatusTypes.PENDING]: color.indigo400,
      [FollowUpStatusTypes.COMPLETED]: color.indigo800
    },
    [FollowUpTypes.RPM_INITIAL_VISIT]: {
      [FollowUpStatusTypes.PENDING]: color.truentityBlue.A200,
      [FollowUpStatusTypes.COMPLETED]: color.truentityCyan[300]
    },
    default: {
      [FollowUpStatusTypes.PENDING]: color.blue600,
      [FollowUpStatusTypes.COMPLETED]: color.green500
    }
  };

  const getEventColor = followup => {
    const { status, type, category } = followup;
    const categoryColors = colorMapping[category] || colorMapping[type] || colorMapping['default'];
    return categoryColors[status] || color.blue500;
  };

  const transformToCalendarEvents = (data: PatientsData[], patientType: PatientsListSearchFilters): EventSourceInput => {
    if (patientType !== PatientsListSearchFilters.ToCallNext) {
      return [];
    }
    const filteredAccounts = data.filter(p => p.accountsFollowupReminders.length > 0);

    const events = filteredAccounts.flatMap(filteredAccount => {
      return filteredAccount?.accountsFollowupReminders.map(followupReminder => {
        return {
          uuid: followupReminder.id,
          status: followupReminder.status,
          title: getAccountUserFullName(filteredAccount.user).toUpperCase(),
          time: formatTime(followupReminder.followUpOn, 'hh:mm A'),
          eventTime: formatTime(followupReminder.followUpOn, 'HH:mm'),
          start: followupReminder.followUpOn,
          type: followupReminder.type,
          subType: followupReminder.subType,
          category: followupReminder.category,
          notes: followupReminder.notes,
          allDay: true,
          id: followupReminder.id,
          relyingPartyAdmin: followupReminder.relyingPartyAdmin,
          extendedProps: {
            patientData: filteredAccount
          },
          color: getEventColor(followupReminder)
        };
      });
    });

    return events;
  };

  const handlePatientData = (response: any, patientType: PatientsListSearchFilters) => {
    const data = response?.accountsLookup?.accounts || [];
    if (data?.length === 0) {
      enqueueSnackbar('No Records Found', { variant: 'info' });
    }
    const calEvents = transformToCalendarEvents(data, patientType);
    setCalendarEvents(calEvents);
    setFilteredPatients(data);
    setRowCount(response?.accountsLookup?.meta?.totalCount ?? 0);
    setTotalResultCount(data?.length ?? 0);
  };

  const fetchGlobalAccountCount = async (values: LookupFilterOptions) => {
    const data = {
      firstName: addIfExists(values.firstName),
      lastName: addIfExists(values.lastName),
      truentityId: addIfExists(values.truentityId),
      phoneNumber: addIfExists(removeNonNumeric(values.phoneNumber)),
      dateOfBirth: addIfExists(formatDate(values.dateOfBirth, 'YYYY-MM-DD'))
    };
    try {
      await getGlobalAccountCount({
        variables: {
          pageNum: 1,
          pageSize: DEFAULT_PAGE_SIZE,
          filterOptions: data
        }
      }).then(() => {
        submittedPatientLookupData.current = JSON.stringify(data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const callGetInfoByLookup = async (values: LookupFilterOptions) => {
    values.dateOfBirth = values.dateOfBirth ? formatDate(values.dateOfBirth, 'YYYY-MM-DD') : undefined;

    const nextCallSuggestedAt =
      values.nextCallSuggestedAt && patientType === PatientsListSearchFilters.ToCallNext
        ? formatDate(values.nextCallSuggestedAt, 'YYYY-MM-DD')
        : undefined;

    if (patientType === PatientsListSearchFilters.ToCallNext && !nextCallSuggestedAt) {
      return;
    }

    try {
      await getInfoByLookupSimplified({
        variables: {
          filterOptions: {
            //filterModel,
            //sortModel,
            firstName: addIfExists(values.firstName),
            lastName: addIfExists(values.lastName),
            phoneNumber: addIfExists(removeNonNumeric(values.phoneNumber)),
            dateOfBirth: addIfExists(values.dateOfBirth),
            truentityId: addIfExists(values.truentityId),
            organization: addIfExists(values.organization),
            orgStore: addIfExists(values.orgStore),
            nextCallSuggestedAt
          },
          pageNum: currentPage + 1,
          pageSize: DEFAULT_PAGE_SIZE,
          relyingPartyAdminId: patientType === PatientsListSearchFilters.Lookup ? 'all' : values.relyingPartyAdmin
        }
      }).then(() => {
        patientType === PatientsListSearchFilters.Lookup && fetchGlobalAccountCount(values);
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to retrieve patients', { variant: 'error' });
    }
  };

  const callGetMyPatients = useCallback(() => {
    getInfoMyPatients({
      variables: {
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE,
        relyingPartyAdminId: patientType
        // filterOptions: {
        //    filterModel,
        //   sortModel
        // }
      }
    })
      .then(response => {
        setPatients(response.data?.accountsAssignedToRelyingPartyAdmin.accounts || []);
        setRowCount(response.data?.accountsAssignedToRelyingPartyAdmin.meta?.totalCount ?? 0);
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar('Unable to get my patients', { variant: 'error' });
      });
  }, [enqueueSnackbar, getInfoMyPatients, patientType, currentPage]);

  const callGetInfoSuperAdmin = useCallback(() => {
    getInfoSuperAdmin({
      variables: {
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        filterOptions:
          patientType === PatientsListSearchFilters.Recent
            ? {
                startDate: formatDateIgnoreTZRemoveDays(new Date(), 7, 'YYYY-MM-DD'),
                endDate: formatDateIgnoreTZ(new Date(), 'YYYY-MM-DD')
              }
            : undefined
      }
    })
      .then(response => {
        setPatients(response.data?.accountsAll.accounts || []);
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar('Unable to get patients', { variant: 'error' });
      });
  }, [enqueueSnackbar, getInfoSuperAdmin, patientType]);

  const callGetInfoProviderAdmin = useCallback(() => {
    getInfo({
      variables: {
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
      .then(response => {
        setPatients(response.data?.accountsByProvider.accounts || []);
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar('Unable to get patients', { variant: 'error' });
      });
  }, [enqueueSnackbar, getInfo]);

  const onMonthChange = useCallback(
    (date: Date) => {
      const values = { ...getValues(), nextCallSuggestedAt: date };
      setValue('nextCallSuggestedAt', values.nextCallSuggestedAt);
    },
    [getValues, setValue]
  );

  const onCalendarEventClick = useCallback(
    async (data: PatientsData, followUpId: string) => {
      setClickedFollowupId(followUpId);
      if (data?.truentityId) {
        try {
          const response = await getCalendarEventDetails({
            variables: {
              truentityId: data?.truentityId
            }
          });
          if (!response.data?.accountGet) {
            throw new Error('Unable to retrieve event details');
          }
          setSelectedPatient({ ...response.data?.accountGet, accountsFollowupReminders: [] });
        } catch (err) {
          console.error(err);
          enqueueSnackbar('Unable to retrieve calendar event details', { variant: 'error' });
        }
      }
    },
    [getCalendarEventDetails, enqueueSnackbar, setClickedFollowupId]
  );

  const onSelectPatient = useCallback(
    (data: PatientsData) => {
      if (data?.truentityId) {
        getPatientDetail({
          variables: {
            truentityId: data?.truentityId
          }
        })
          .then(response => {
            if (!response.data?.accountGet) {
              throw new Error('Unable to find patient');
            }
            setSelectedPatient({ ...response.data?.accountGet });
          })
          .catch(err => {
            console.error(err);
            enqueueSnackbar('Unable to retrieve all of the customer data', {
              variant: 'error'
            });
          });
      }
    },
    [enqueueSnackbar, getPatientDetail]
  );

  const handleLookupSearchQuery = () => {
    resetLookupForm();
    if (searchQuery.filterOption === '') {
      const searchValue = searchQuery.value;
      if (searchValue?.length > 1) {
        if (searchValue.split(' ').length > 1) {
          setValue('firstName', searchValue?.split(' ')[0]);
          setValue('lastName', searchValue?.split(' ')[1]);
        } else {
          setValue('firstName', searchValue?.split(' ')[0]);
          setValue('lastName', '');
        }
        formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    } else if (searchQuery.filterOption in formDefault) {
      setValue(searchQuery.filterOption as keyof LookupFilterOptions, searchQuery.value);
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  const calculatePatientType = useCallback(() => {
    const patientsSearchFilter = searchParams.get('search') || PatientsListSearchFilters.Lookup;
    setPatientType(patientsSearchFilter as PatientsListSearchFilters);
    const query = searchParams.get('q');
    const filterOption = searchParams.get('filterOption') || '';
    if (query) {
      setSearchQuery({
        value: query,
        filterOption: filterOption
      });
    } else {
      setSearchQuery({
        value: '',
        filterOption: ''
      });
    }
  }, [searchParams]);

  const navigateToGlobalLookUp = useCallback(() => {
    const { firstName, lastName, phoneNumber, dateOfBirth } = getValues();

    const queryParams =
      globalAccountCount > 0
        ? new URLSearchParams({
            firstName: firstName || '',
            lastName: lastName || '',
            phoneNumber: phoneNumber || '',
            dateOfBirth: formatDate(dateOfBirth, 'YYYY-MM-DD') || ''
          }).toString()
        : '';
    navigate(`/global-lookup?${queryParams}`);
  }, [getValues, navigate, globalAccountCount]);

  useEffect(() => {
    if (globalAccountCountData && globalAccountCountData.accountsSearch && globalAccountCountData.accountsSearch.meta) {
      const {
        meta: { totalCount }
      } = globalAccountCountData.accountsSearch;

      if (totalCount && totalResultCount < totalCount) {
        setGlobalAccountCount(totalCount);
        submittedGlobalAccountCount.current = totalCount;
      }
    }
  }, [globalAccountCountData, totalResultCount]);

  useEffect(() => {
    const relyingPartyAdmin = isSuperAdmin || isPSM ? 'all' : currentUser?.id;
    const initialFormDefault: LookupFilterOptions = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      truentityId: '',
      dateOfBirth: null,
      nextCallSuggestedAt: getFirstDateOfCurrentMonth(new Date()),
      organization: 'all',
      relyingPartyAdmin: relyingPartyAdmin,
      orgStore: undefined
    };
    setFormDefault(initialFormDefault);
    setValue('relyingPartyAdmin', relyingPartyAdmin);
  }, [isSuperAdmin, currentUser, relyingPartiesAdminAll, setValue, isPSM]);

  useEffect(() => {
    if (lookupData && !loadingPatients && patientType) {
      handlePatientData(lookupData, patientType);
    }
  }, [loadingPatients, lookupData, patientType]);

  useEffect(() => {
    setFilteredPatients([]);
    calculatePatientType();
  }, [calculatePatientType]);

  useEffect(() => {
    if (patientType === PatientsListSearchFilters.Lookup) {
      handleLookupSearchQuery();
    }
  }, [patientType, searchQuery]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [patientType, setContent]);

  useEffect(() => {
    setPatients([]);
  }, [patientType]);

  useEffect(() => {
    if (accountError || adminAccountError || myPatientsError) {
      handleGraphQLError((accountError || adminAccountError || myPatientsError) as ApolloError, errorHandlerMap);
    }
  }, [accountError, adminAccountError, errorHandlerMap, myPatientsError]);

  useEffect(() => {
    switch (patientType) {
      default:
        callGetMyPatients();
        break;
      case PatientsListSearchFilters.Lookup:
      case PatientsListSearchFilters.ToCallNext:
        break;
      case PatientsListSearchFilters.Recent:
      case PatientsListSearchFilters.All: {
        if (isProviderAdmin) {
          callGetInfoProviderAdmin();
        } else {
          callGetInfoSuperAdmin();
        }
        break;
      }
    }
  }, [isProviderAdmin, patientType, currentPage]);

  const isMyPatients = () => {
    const isMyPatients =
      patientType !== PatientsListSearchFilters.Lookup &&
      patientType !== PatientsListSearchFilters.ToCallNext &&
      patientType !== PatientsListSearchFilters.Recent &&
      patientType !== PatientsListSearchFilters.All;
    console.warn('isMyPatients', isMyPatients);
    return isMyPatients;
  };

  useEffect(() => {
    if (relyingPartyAdminDataCalled && !relyingPartyAdminDataLoading && relyingPartyAdminData) {
      setRelyingPartyAdmins(relyingPartyAdminData.relyingPartyAdminsAll.relyingPartyAdmins);
      setRelyingPartiesAdminAll([
        ...SELECT_DEFAULT_ADMIN,
        ...relyingPartyAdminData.relyingPartyAdminsAll.relyingPartyAdmins
      ] as RelyingPartyAdminsObject[]);
    }
  }, [relyingPartyAdminDataLoading, relyingPartyAdminData, relyingPartyAdminDataCalled, setRelyingPartyAdmins]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations([
        ...SELECT_DEFAULT,
        ...organizationData.clientOrganizations.clientOrganizations
      ] as ClientOrganizationObject[]);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    setFilteredClientStores([]);
    setValue('orgStore', '');

    const org = clientOrganizations?.find(o => o.id === selectedOrg);
    if (org && org.clientStores?.length > 0) {
      setFilteredClientStores([...SELECT_DEFAULT, ...org.clientStores]);
    }
  }, [clientOrganizations, selectedOrg, setValue]);

  useEffect(() => {
    if (errorLookup) {
      console.log('Error getting Patient Accounts. If auth error, user will be logged out.');
      console.error(errorLookup);
      handleGraphQLError(errorLookup, errorHandlerMap);
    }
  }, [errorLookup, errorHandlerMap]);

  useEffect(() => {
    if (patientType === PatientsListSearchFilters.ToCallNext) {
      callGetInfoByLookup(formDefault).catch(err => console.error(err));
    }
  }, [patientType]);

  useEffect(() => {
    if (isFollowUpUpdated) {
      refetchLookups({
        filterOptions: {
          organization: addIfExists(getValues('organization')),
          orgStore: addIfExists(getValues('orgStore')),
          nextCallSuggestedAt: formatDate(getValues('nextCallSuggestedAt') as Date, 'YYYY-MM-DD')
        },
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        relyingPartyAdminId: getValues('relyingPartyAdmin')
      })
        .catch(err => console.error(err))
        .finally(() => setIsFollowUpUpdated(false));
    }
  }, [isFollowUpUpdated, getValues]);

  useEffect(() => {
    // if value changed account count is resetting
    const currentFormValues = {
      firstName: addIfExists(firstName),
      lastName: addIfExists(lastName),
      dateOfBirth: addIfExists(formatDate(dateOfBirth, 'YYYY-MM-DD')),
      phoneNumber: addIfExists(removeNonNumeric(phoneNumber))
    };

    if (JSON.stringify(currentFormValues) !== submittedPatientLookupData.current) return setGlobalAccountCount(0);
    else setGlobalAccountCount(submittedGlobalAccountCount.current);
  }, [firstName, lastName, dateOfBirth, phoneNumber]);

  return (
    <Stack spacing={collapseFilter ? 1.5 : 0.5}>
      <Stack direction="row" justifyContent={'flex-end'}>
        {patientType === 'to-call-next' && (
          <Button
            onClick={() => setCollapseFilter(!collapseFilter)}
            startIcon={collapseFilter ? <FilterAltIcon /> : <FilterAltOffIcon />}
            label="Filter"
          />
        )}
        <Stack direction={'row'} spacing={1} flex={1} justifyContent={'flex-end'}>
          <PatientAdd refetch={refetchPatients} />
          {patientType === PatientsListSearchFilters.Lookup && (
            <Badge badgeContent={globalAccountCount} color="error">
              <Button
                startIcon={<TravelExploreIcon />}
                label="Global Lookup"
                variant="outlined"
                color="info"
                onClick={navigateToGlobalLookUp}
              />
            </Badge>
          )}
        </Stack>
      </Stack>

      {patientType === PatientsListSearchFilters.Lookup && (
        <Stack
          flex={1}
          mr={1}
          direction="row"
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          component={'form'}
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField sx={{ width: '24.7%' }} autoFocus onChange={onChange} value={value} label={'First Name'} />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField sx={{ width: '24.7%' }} onChange={onChange} value={value} label={'Last Name'} />
            )}
          />

          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value } }) => (
              <TruentityPhoneNumber
                sx={{ width: '24.7%' }}
                onChange={event => {
                  const inputValue = event.target.value.replace(/\D/g, '');
                  onChange(phoneNumberFormat(inputValue));
                }}
                editable={true}
                value={value}
                label={'Phone Number'}
              />
            )}
          />

          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { onChange, value } }) => (
              <TruentityDatePicker sx={{ width: '24.7%' }} onChange={onChange} value={value} label={'Date of Birth'} />
            )}
          />

          <Grid container spacing={1} pb={1} pt={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={color.truentityCyan[600]} fontSize={'small'} component="div">
                Lookup Patients by First Name, Last Name or Date of Birth, or Phone Number or their combinations. You may enter first few
                letters for first and last names (in either lowercase or uppercase). Full phone numbers must be entered for the searches.
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ mt: '0.5rem' }}>
              <Stack direction={'row'}>
                <Button startIcon={<SearchIcon />} label={'Lookup'} type="submit" size="small" sx={{ marginRight: 1 }} />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}

      <Collapse in={collapseFilter} timeout="auto">
        {patientType === PatientsListSearchFilters.ToCallNext && (
          <Stack
            flex={1}
            direction="column"
            flexWrap={'wrap'}
            justifyContent={'flex-start'}
            component={'form'}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="nextCallSuggestedAt"
              render={({ field: { onChange, value } }) => (
                <TruentityDatePicker
                  sx={{ width: '33%', marginRight: 1 }}
                  onChange={onChange}
                  value={value}
                  label={'Follow Up (on or after)'}
                  format={'YYYY-MM-DD'}
                />
              )}
            />

            <Stack direction="row">
              <Controller
                control={control}
                name="organization"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <FormControl sx={{ width: '33%', marginRight: 1 }} variant="outlined" margin="dense" size="medium">
                    <InputLabel id={'organizations-input'}>Organization</InputLabel>

                    <Select
                      labelId={'organizations'}
                      label={'Organizations'}
                      placeholder={'select an organization'}
                      value={value}
                      onChange={(event: SelectChangeEvent<string>) => {
                        onChange(event.target.value);
                      }}
                    >
                      {clientOrganizations.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="relyingPartyAdmin"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <FormControl sx={{ width: '33%', marginRight: 1 }} variant="outlined" margin="dense" size="medium">
                    <InputLabel id={'relyingPartyAdmin-input'}>User</InputLabel>
                    <Select
                      labelId={'relyingPartyAdmin-input'}
                      label={'User'}
                      placeholder={'select an admin'}
                      value={value}
                      onChange={event => {
                        onChange(event.target.value);
                        handleSubmit(onSubmit)();
                      }}
                    >
                      {relyingPartiesAdminAll?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              {selectedOrg && selectedOrg !== 'all' && filteredClientStores?.length > 0 && (
                <Controller
                  control={control}
                  name="orgStore"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: '33%' }} variant="outlined" fullWidth margin="dense" size="medium">
                      <InputLabel id={'organizations-input'}>Location</InputLabel>

                      <Select
                        labelId={'stores'}
                        label={'Stores'}
                        placeholder={'select a store'}
                        value={value}
                        onChange={(event: SelectChangeEvent<string>) => {
                          onChange(event.target.value);
                        }}
                      >
                        {filteredClientStores.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              )}
            </Stack>

            <Grid container spacing={1} pb={1} pt={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color={color.truentityCyan[600]} fontSize={'small'} component="div">
                  Lookup Patients to Follow Up
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ mt: '0.5rem' }}>
                <Button label={'Lookup'} type="submit" size="small" />
              </Grid>
            </Grid>
          </Stack>
        )}
      </Collapse>

      {patientType && !['lookup', 'to-call-next'].includes(patientType) && (
        <PatientsDataFilter
          clientOrganizations={clientOrganizations}
          patientType={patientType}
          sx={{ flex: 1 }}
          patients={patients}
          filteredPatients={filteredPatients}
          setFilteredPatients={setFilteredPatients}
          resetFilter={resetFilter}
        />
      )}

      <Paper component={Stack} direction="column" spacing={2}>
        <LoadingOverlay active={loadingPatients} text="Loading Patients..." />
        <MuiTabs
          tabVariant="standard"
          hideDisabled={true}
          tabs={[
            {
              label: 'Calendar',
              children: (
                <Calendar
                  onMonthChange={onMonthChange}
                  events={calendarEvents}
                  setIsFollowUpUpdated={setIsFollowUpUpdated}
                  onEventClick={e =>
                    onCalendarEventClick(
                      {
                        ...e.event.extendedProps.patientData
                      } as PatientsData,
                      e.event.id
                    )
                  }
                />
              ),
              disabled: patientType !== PatientsListSearchFilters.ToCallNext
            },
            {
              label: 'List',
              children: (
                <div style={{ display: 'flex' }}>
                  <TruentityDataGrid
                    name={'dg-patients'}
                    paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                    onPaginationModelChange={({ page }) => updateData(page)}
                    sortModel={sortModel}
                    onSortModelChange={newModel => {
                      setSortModel(newModel);
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={newModel => {
                      setFilterModel(newModel);
                    }}
                    autoHeight
                    rows={filteredPatients}
                    rowCount={isMyPatients() ? rowCount : undefined}
                    loading={loadingSuperAdmin || loading || loadingPatients || loadingMyPatients}
                    columns={columns}
                    paginationMode={isMyPatients() ? 'server' : 'client'}
                    disableRowSelectionOnClick
                    onRowClick={e => onSelectPatient(e.row as PatientsData)}
                    initialState={{
                      sorting: {
                        sortModel
                      }
                    }}
                  />
                </div>
              )
            }
          ]}
          preSelectedTabIndex={0}
        />
      </Paper>

      <PatientInfoDrawer patientsData={selectedPatient} followupId={clickedFollowupId} refetchPatientDetail={refetchLookups} />
    </Stack>
  );
};

export default Patients;
