import { Message, SenderType } from '@/types/coPilot';
import { Avatar, Box, CircularProgress, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import Markdown from 'react-markdown';
import TruentityIcon from '../TruentityIcon';

interface CoPilotMessageProps {
  message: Message;
  sendMessageLoading?: boolean;
  isLastMessage?: boolean;
}

const CoPilotMessage: React.FC<CoPilotMessageProps> = ({ message, sendMessageLoading, isLastMessage }) => {
  const theme = useTheme();
  return (
    <Stack direction={message.sender === SenderType.BOT ? 'row' : 'row-reverse'} spacing={1}>
      {message?.sender === SenderType.BOT && (
        <Avatar
          sx={{
            width: 24,
            height: 24,
            backgroundColor: 'transparent',
            border: 1,
            borderWidth: '0.5px',
            borderColor: '#20416E4D',
            padding: '2px'
          }}
        >
          <TruentityIcon />
        </Avatar>
      )}

      <Box
        sx={{
          padding: 1,
          borderRadius: message.sender === SenderType.USER ? '32px 0 32px 32px' : '0 32px 32px 32px',
          backgroundColor: message.sender === SenderType.BOT ? theme.palette.background.paper : theme.palette.primary.dark,
          color: message.sender === SenderType.BOT ? '#000' : '#fff',
          maxWidth: message.sender === SenderType.BOT ? '90%' : '80%',
          position: 'relative',
          overflow: 'visible',
          alignItem: 'center',
          justifyContent: 'center'
        }}
      >
        <Box py="0.5rem" px="0.25rem">
          <Markdown components={{ p: 'div' }}>{message.text}</Markdown>
        </Box>

        {sendMessageLoading && message.sender === SenderType.USER && isLastMessage && (
          <CircularProgress
            size={15}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '-20px',
              marginTop: '-7.5px',
              zIndex: 1
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default CoPilotMessage;
