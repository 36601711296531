import CopilotMessageSubscriptionHandler from '@/hooks/useCopilotMessage';
import CoPilotWindow from '.';

const StandAloneCopilotWindow = () => {
  return (
    <>
      <CopilotMessageSubscriptionHandler />
      <CoPilotWindow isPoppedToNewWindow={true} />
    </>
  );
};

export default StandAloneCopilotWindow;
