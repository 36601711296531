import CoPilotWindow from '@/components/CoPilotWindow';
import config from '@/config';
import CopilotMessageSubscriptionHandler from '@/hooks/useCopilotMessage';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import { MedicalServices } from '@/types/admin';
import { isCopilotEnabled } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { isModeProduction } from '@/util/modes';
import { useCopilotMessageStore } from '@/zustand/CoPilotMessageStore';
import { useReactiveVar } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsightsIcon from '@mui/icons-material/Insights';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

const IconButtonStyle = {
  border: '1px solid lightGrey',
  size: 'small'
};

export default function PatientInfoTabsButtonMenu(props: {
  phone: string | undefined;
  setIsMessageDrawerOpen: (val: boolean) => void;
  goToInsights: () => void;
  toggleAssignedProviders: () => void;
  togglePatientInfo: () => void;
  goToNotes: () => void;
  goToAlerts: () => void;
  collapsePatientInfo: boolean;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);

  const [showAiCopilot, setShowAiCopilot] = useState<boolean>(false);
  const [showCoPilotWindow, setShowCoPilotWindow] = useState<boolean>(false);
  const isPoppedToNewWindow = useCopilotMessageStore(state => state.isPoppedToNewWindow);
  const popupWindow = useCopilotMessageStore(state => state.popupWindow);
  const setIsPoppedToNewWindow = useCopilotMessageStore(state => state.setIsPoppedToNewWindow);
  const setPopupWindow = useCopilotMessageStore(state => state.setPopupWindow);

  const toggleCoPilotWindow = () => {
    popupWindow ? popupWindow.focus() : setShowCoPilotWindow(prevState => !prevState);
  };

  useEffect(() => {
    if (currentUser) {
      const enableCopilot = isCopilotEnabled(currentUser);
      setShowAiCopilot(enableCopilot);
    }
    return () => {
      popupWindow?.close();
    };
  }, [currentUser, popupWindow]);

  useEffect(() => {
    const handleWindowClose = () => {
      if (popupWindow?.closed) {
        setIsPoppedToNewWindow(false);
        setPopupWindow(null);
      }
    };

    const intervalId = setInterval(handleWindowClose, 300);

    return () => clearInterval(intervalId);
  }, [popupWindow]);

  return (
    <Stack direction="row" spacing={1} sx={{ border: config.DEBUG_LAYOUT ? '5px solid black' : undefined, overflowX: 'auto' }}>
      {props.phone === null ? (
        <Tooltip title="Patient needs a contact number">
          <IconButton
            onClick={() => {
              enqueueSnackbar('No phone number associated with this patient', {
                variant: 'error'
              });
            }}
            sx={IconButtonStyle}
          >
            <MessageIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Messaging">
          <IconButton
            onClick={() => {
              props.setIsMessageDrawerOpen(true);
            }}
            sx={IconButtonStyle}
          >
            <MessageIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {!isModeProduction() && (
        <Tooltip title="Insights">
          <IconButton onClick={props.goToInsights} sx={IconButtonStyle}>
            <InsightsIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {isRPMViewable && (
        <Tooltip title="Providers">
          <IconButton onClick={props.toggleAssignedProviders} sx={IconButtonStyle}>
            <LocalHospitalIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {isRPMViewable && (
        <Tooltip title="Notes">
          <IconButton onClick={props.goToNotes} sx={IconButtonStyle}>
            <TextSnippetIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {isRPMViewable && (
        <Tooltip title="Alerts">
          <IconButton onClick={props.goToAlerts} sx={IconButtonStyle}>
            <NotificationsIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {isRPMViewable && showAiCopilot && (
        <>
          <Tooltip title="Chat">
            <IconButton onClick={toggleCoPilotWindow} sx={IconButtonStyle}>
              <SmartToyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <CopilotMessageSubscriptionHandler />
          {showCoPilotWindow && (
            <CoPilotWindow title="AI Copilot" onClose={() => setShowCoPilotWindow(false)} isHidden={isPoppedToNewWindow} />
          )}
        </>
      )}

      <Tooltip title="Collapse">
        <IconButton aria-label="collapse" onClick={props.togglePatientInfo} sx={IconButtonStyle}>
          <ExpandMoreIcon fontSize="small" sx={{ transform: props.collapsePatientInfo ? 'rotate(0deg)' : 'rotate(180deg)' }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
